import React from "react";

// markup
const NotFoundPage = () => {
    return (
        <p>No Such Page...</p>
    )
};

export default NotFoundPage;
